import {
  type ClientLoaderFunction,
  ClientLoaderFunctionArgs
} from '@remix-run/react';

import storage from '~/lib/pwa/storage';

export function defineClientLoader<T extends ClientLoaderFunction>(
  loader: T,
  options?: { hydrate: boolean }
) {
  const clientLoader = function proxy(args: ClientLoaderFunctionArgs) {
    return loader({
      ...args,
      context: {
        async cache(serverLoader, { strategy, key, adapter = storage.route }) {
          const existingData = await adapter
            .getItem(key)
            .catch((error) => console.warn(error));

          if (strategy === 'normal' && existingData) {
            return {
              ...existingData,
              meta: {
                serverData: existingData,
                deferredServerData: undefined,
                key
              }
            };
          }

          const data = existingData ? existingData : await serverLoader();
          if (data instanceof Response) {
            throw data;
          }

          if (existingData !== data && data) {
            await adapter.setItem(key, data);
          }

          async function defferData() {
            try {
              return await serverLoader();
            } catch (error) {
              console.warn(error);
              // Return response or error to avoid overwriting the exitsting data if any.
              return Response.error();
            }
          }

          const deferredServerData = existingData ? defferData() : undefined;

          return {
            ...(data ?? existingData),
            meta: {
              serverData: data ?? existingData,
              deferredServerData,
              key
            }
          };
        }
      }
    });
  };

  clientLoader.hydrate = options?.hydrate ?? true;

  return clientLoader as unknown as T;
}
